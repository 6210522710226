import '../css/Welcome.css';


export default function WelcomeComponent ({ className }) {
    return (
      <>
            <div className={className}>

        <div id="container">
          ¡Bienvenido 
          <div id="flip">
            <div><div>Asistente</div></div>
            <div><div>Contable</div></div>
            <div><div>Administrativo</div></div>
          </div>
          A APP's WEB PERÚ!
        </div>
        </div>
      </>
    );
  }
  