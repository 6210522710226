import React, { createContext, useContext, useState, useEffect } from 'react';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import { v4 as uuidv4 } from 'uuid';
import {API_URL} from '../constants/constants'

const StompClientContext = createContext();

export const useStompClient = () => useContext(StompClientContext);

export default function StompClientProvider ({ children }) {

  const [stompClient, setStompClient] = useState(null);
  const [myUuid, setMyUuid] = useState(uuidv4());
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [isDisconnectError, setIsDisconnectError] = useState(false);

const connectStompClient = () => {
  let Sock = new SockJS(API_URL+'ws-stomp?uuid='+myUuid);
  const client = over(Sock);


    client.connect({}, () => {
      // console.log("Connected to STOMP server");
      setStompClient(client);
    }, (error) => {
      console.error("Error connecting to STOMP server:", error);
      setIsDisconnectError(true)
    });

};

  
const disconnectStompClient=()=>{

  if (stompClient && stompClient.connected) {
    stompClient.disconnect(() => {
      // console.log('Disconnected from STOMP server');
    });
  }
}
  

  // const initValidation = (infoProcess) => {

   
  //   const infoToRequest={
  //     id:infoProcess.myUuid,nameUser:infoProcess.username,state:"",
  //     process:"",progress:0 ,total:infoProcess.dataXlsx.length,
  //     electronicDocumentDTOS:infoProcess.dataXlsx

  //   }

  //   if (stompClient ) {

  //     try {

  //       stompClient.send("/app/new-validation", {}, JSON.stringify(infoToRequest));
  //       // Aquí podrías implementar una lógica de confirmación esperando un mensaje de respuesta
  //     } catch (error) {
  //       console.error('Error ++++al enviar el mensaje: ', error);
  //       // Manejar el error como sea apropiado para tu aplicación
  //     }


  //   }
  // };



  const subscribeListLiveUser = (destination, callback) => {

    if (stompClient && stompClient.connected) {
      stompClient.subscribe(destination, (message) => {
        callback(JSON.parse(message.body));
      });
    }
  };


  const handleReceivedMessage = (message) => {
    const messageData = message;
    setReceivedMessages(prevMessages => [...prevMessages, messageData]);
    // Aquí podrías implementar lógica adicional si necesitas procesar los mensajes de alguna manera
  };

  const subscribeInitValidation = (destination) => {
///queue/'+myUuid+'/notification
    if (stompClient && stompClient.connected) {
      stompClient.subscribe(destination, handleReceivedMessage);
    }
  };


  



  return (
    <StompClientContext.Provider value={{connectStompClient,disconnectStompClient, stompClient, subscribeListLiveUser, isConnected: stompClient?.connected ?? false ,myUuid,subscribeInitValidation,receivedMessages,isDisconnectError}}>
    {children}
  </StompClientContext.Provider>
  );
};


