import ShowServicesZoom from './components/ShowServicesZoom';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ValidAPIStepper from './components/ValidAPIStepper';
import './css/Welcome.css';
import WelcomeComponent from './components/WelcomeComponent';
import LiveUsersPublic from './components/LiveUsersPublic';
import ValidAPIProcess from './components/ValidAPIProcess';
import StompClientProvider from './components/StompClientContext';
import ValidAPIProvider from './components/ValidAPIContext';
import Footer from './components/Footer';

const App = () => {

  return (
    
        <BrowserRouter>

        <ResponsiveAppBar/>
<br></br>
        <Routes>

        <Route path="/" element={
    <React.Fragment>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '87vh', // Cambiado de minHeight a height
      width: '100%', 
      boxSizing: 'border-box',
      overflow: 'hidden' // Evita scroll innecesario
    }}>
      <div style={{ flex: 1, overflowY: 'auto' }}> {/* Permite scroll en contenido principal si es necesario */}
        <WelcomeComponent className="welcomeComponentClass" />
        <ShowServicesZoom className="showServicesZoomClass" />
      </div>
      <Footer />
    </div>
  </React.Fragment>
        } />


        <Route path="/stepper" element={
          <StompClientProvider>
                  <React.Fragment>
                  <ValidAPIProvider>
                    <ValidAPIStepper />
                    </ValidAPIProvider>
                <LiveUsersPublic/>

                  </React.Fragment>
                  </StompClientProvider>
         } />


      <Route path="/validacion" element={
        <StompClientProvider>
                  <React.Fragment>
                  <ValidAPIProvider>
                    <ValidAPIProcess />
                    </ValidAPIProvider>
                <LiveUsersPublic/>
                  </React.Fragment>

                  </StompClientProvider>
         } />

        </Routes>

      </BrowserRouter>


   )
}

export default App;
