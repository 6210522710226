import React, { createContext, useContext, useState, useEffect } from 'react';
import {API_URL} from '../constants/constants'

const ValidAPIContext = createContext();

export const useValidAPIClient = () => useContext(ValidAPIContext);

export default function ValidAPIProvider ({ children }) {

  const [statusCode, setStatusCode] = useState(0);

    const initValidationAPI = async (validApiInfo) => {

        const response = await fetch(API_URL+'process-valid-api/pre-validation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(validApiInfo), // Envía el estado o el objeto que tengas preparado
        });

        setStatusCode(response.status)
      };





  return (
    <ValidAPIContext.Provider value={{initValidationAPI,statusCode}}>
    {children}
  </ValidAPIContext.Provider>
  );
};


