import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'primary.dark',
        color: 'white',

        pt: 1, // padding-top
        pb: 2, // padding-bottom
        textAlign: 'center'
      }}
    >
      <Typography variant="body1">
        Para una mejor experiencia, usa 
        <Link href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', marginLeft: 8 }}>Chrome</Link>, 
        <Link href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', marginLeft: 8 }}>Firefox </Link> 
         o 
        <Link href="https://www.microsoft.com/edge" target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', marginLeft: 8 }}>Edge</Link>.
      </Typography>
    </Box>
  );
}

export default Footer;
