import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useStompClient } from './StompClientContext';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import LinearProgress from '@mui/material/LinearProgress';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useValidAPIClient } from './ValidAPIContext';

import * as XLSX from 'xlsx';

import {API_URL} from '../constants/constants'
import {LIMIT_ACTIVE_PROCESSES} from '../constants/constants'
import ModalLimitIp from './ModalLimitIp';


const columns = [
  { id: 'state', label: 'Estado SUNAT', minWidth: 30 },
  { id: 'ruc', label: 'RUC', minWidth: 30 },
  { id: 'typeCpe', label: 'Tipo', minWidth: 15 },
  { id: 'document', label: 'Documento', minWidth: 20 },
  {
    id: 'dateEmmit',
    label: 'Fecha Emisión',
    minWidth: 20,
    align: 'right',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 20,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  { id: 'stateRuc', label: 'Estado contribuyente', minWidth: 20 },
  { id: 'conditionAddress', label: 'Condición domicilio', minWidth: 20 },
  { id: 'comment', label: 'Comentario SUNAT', minWidth: 20 },
];

function createData(state,ruc, typeCpe, document, dateEmmit,total,stateRuc,conditionAddress,comment) {

  return { state,ruc, typeCpe, document, dateEmmit,total,stateRuc,conditionAddress,comment };
}

var rows = [];

function transformObject(original) {
  return {
    "Estado SUNAT": original.state,
    "RUC": original.ruc,
    "Tipo": original.typeCpe,
    "Documento": original.document,
    "Fecha Emisión": original.dateEmmit,
    "Total": original.total,
    "Estado contribuyente": original.stateRuc,
    "Condición domicilio": original.conditionAddress,
    "Comentario SUNAT": original.comment
  };
}


const generateFormat=()=>{

  const newRows=rows.map(obj => transformObject(obj));

  const ws = XLSX.utils.json_to_sheet(newRows);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Comprobantes');

  XLSX.writeFile(wb,"Comprobantes_Validados.xlsx")
  
}


export default function ValidAPIProcess () {
  const location = useLocation();
  const navigate = useNavigate();

  const {myUuid,isConnected,stompClient,receivedMessages } = useStompClient();
  const [documents, setDocuments] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalDocumentsInProcess, setTotalDocumentsInProcess] = useState(0);
  // const [isFinished,setIsFinished]=useState(false);  
  const [indicatorProgress,setIndicatorProgress]=useState(-1);//-2 wait for server (not active users  ),-1 wait with other users,0 progress,1 terminated

  const { statusCode } = useValidAPIClient();


  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal=()=>{

    setIsModalOpen(false)
    // console.log("cerrando modal....")
    navigate('/')
  }



  useEffect(() => {

    const infoProcess = location.state?.newProcess;

    if (infoProcess === undefined || infoProcess === null) {
      // console.log("indefinido");
      navigate('/stepper');
    }

    if(statusCode===429 || statusCode===400){
      setIsModalOpen(true)

    }else if(statusCode===0){

    }else if(statusCode===200){





      if (infoProcess === undefined || infoProcess === null) {
        // console.log("indefinido");
        navigate('/stepper');
      }else{
        setTotalDocuments(infoProcess.dataXlsx.length)
      }
  
      //   console.log("este es el dato completo.")
      // console.log(infoProcess)
  
      
      if (receivedMessages.length > 0) {
        const latestMessage = receivedMessages[receivedMessages.length - 1];
  
        if(latestMessage.body==="OK"){
  
  
          // console.log("ENTRANDON PORQUE SÌ SE ENCONTRÓ MENSAJE OK ")
          const fetchData = async () => {
            const response = await fetch(API_URL+'process-valid-api/init?uuid='+myUuid);
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
  
            let accumulatedData = ''; // Acumula datos para formar un JSON completo
  
            const processStream = () => reader.read().then(({ done, value }) => {
              if (done) {
                // console.log("TRATAR DE MANEJAR EL ERROR.");
                // console.log('Stream completado');
                setIndicatorProgress(1)
                // setIsFinished(true)
                return;
              }
  
              // Acumula el stream de datos
              accumulatedData += decoder.decode(value, { stream: true });
  
              // console.log("recibiendo este dato")
              // console.log(accumulatedData)
              // console.log(accumulatedData.length)
  
              if(accumulatedData.length>12){
              // Intenta procesar cada mensaje completo que inicie con "data:"
              accumulatedData.split('\n').forEach((line) => {
                if (line.startsWith('data:[')) {
  
  
                    try {
                      setIndicatorProgress(0)
                      const json = JSON.parse(line.replace('data:', '').trim());
                      console.log("parseado....");
                      console.log(json);
                      // Asume json como un array y actualiza el estado agregando los nuevos documentos
                      if (Array.isArray(json)) {
                        setDocuments(prevDocs => [...prevDocs, ...json]);
                        json.forEach((data)=>{
  
                          rows.push(createData(data.state,data.ruc,data.typeCpe,data.series+"-"+data.correlative,data.dateEmmit,data.total,data.stateRuc,data.conditionAddress,data.comment))
                          console.log("haciendo for each")
                          console.log(data)
                          console.log(rows)
                        });
                        setTotalDocumentsInProcess(rows.length)
  
                      }
                    } catch (e) {
                      // console.log("hubo un problema..")
                      console.log(line)
                      console.error('Error parsing JSON:', e);
                    }
  
  
                }
              });
  
              // Limpia accumulatedData si se asume que cada mensaje está completo por línea
              accumulatedData = '';
              }
              // Continúa leyendo el stream
              processStream();
            });
  
  
            // Inicia el procesamiento del stream
            processStream();
          };
  
          fetchData();  
        }
  
      }else{
  
        // console.log("aún no se iniciará el proceso")
      }

    }//testeando 200......




   return () => {

rows=[]
 setTotalDocuments(0);
setTotalDocumentsInProcess(0);

      if (stompClient && stompClient.connected) {
        // console.log("estamos descon34%5343423")
        //   stompClient.disconnect(() => {

        // });
      }
    };

  }, [isConnected, location.state, navigate,receivedMessages,statusCode]);//



  return (



    <>
        <ModalLimitIp open={isModalOpen} handleClose={onCloseModal} />
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%',gap:2 }}>
    <Card sx={{ width: '30%', minWidth: 200 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Validación de comprobantes
        </Typography>
        <Typography variant="h5" component="div">
        {indicatorProgress === -1 ? 'Esperando' :
     indicatorProgress === 0 ? 'Procesando...' :
     'Terminado'}
        {/* {isFinished ? 'Terminado' : 'Procesando...'} */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {totalDocumentsInProcess} de {totalDocuments}
        </Typography>


        <Typography variant="body2">

        {indicatorProgress === -1 ? 'Hay usuarios con procesos activos \n Tu proceso iniciará cuando estes en la posición '+LIMIT_ACTIVE_PROCESSES+'  o una menor' :
     indicatorProgress === 0 ? 'La tabla de la derecha te mostrará \n los comprobantes validados' :
     '¡Validación terminada!'}
        </Typography>


      </CardContent>
      <CardActions>
        <Button onClick={generateFormat} size="small"
        
        disabled={indicatorProgress === -1} // Desactiva el botón si indicatorProgress es -1
        style={{
          backgroundColor:
            indicatorProgress === -1 ? 'gray' : // Color plomo cuando está desactivado
            indicatorProgress === 0 ? 'blue' : // Color azul cuando está activo y el valor es 0
            'green', // Color verde cuando está activo y el valor es 1
          color: 'white', // Texto blanco para mayor contraste
        }}
        >
        
        {indicatorProgress === -1 ? 'Esperando' :
     indicatorProgress === 0 ? 'Descargar avance' :
     'Descargar validación final'}
        
        </Button>
      </CardActions>
    </Card>

    
    <Paper sx={{ width: '70%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 200 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index }>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      </Box>

      {indicatorProgress===1 ? (
<p></p>
        ) : (
          <LinearProgress />
        )}

      
      </>

  );
};

