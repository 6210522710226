import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as XLSX from 'xlsx';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Key from '@mui/icons-material/Key'
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import {  useState,useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useStompClient } from './StompClientContext';
import LinearProgress from '@mui/material/LinearProgress';
import { useValidAPIClient } from './ValidAPIContext';
import {MAX_DOCUMENT_VALID,URL_CREDENTIAL_API,API_URL} from '../constants/constants'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const steps = [
  {
    label: 'Usuarios activos',
    content: 'question',
  },
  {
    label: 'Escribe tu nombre',
    content: 'nameStepContent',
  },
  {
    label: 'Registra tus credenciales',
    content: 'credentialsStepContent',
  },
  {
    label: 'Sube tu Excel',
    content: 'credentialsStepContent',
  }
];

const randomNames = [
  { label: 'DILMA ROUSSEFF', year: 1994 },
  { label: 'ALBERTO FUJIMORI', year: 1994 },
  { label: 'PPK', year: 1994 },
  { label: 'ANGELA MERKEL', year: 1994 },
  { label: 'JOE BIDEN', year: 1994 },
  { label: 'NICOLÁS MADURO', year: 1994 },
  { label: 'JAVIER MILEI', year: 1994 },
  { label: 'DINA BOLUARTE', year: 1994 },
  { label: 'GABRIEL BORICH', year: 1994 },
  { label: 'V. ZELENSKI', year: 1994 },
  { label: 'VLADIMIR PUTIN', year: 1994 },
  { label: 'E. MACRON', year: 1994 },
  { label: 'LULA DA SILVA', year: 1994 },
  { label: 'ALAN GARCÍA', year: 1994 },
  { label: 'PEDRO CASTILLO', year: 1994 },
  { label: 'ALEJANDRO TOLEDO', year: 1994 },
  { label: 'NAYIB BUKELE', year: 1994 },
  { label: 'DONALD TRUMP', year: 1994 },
  { label: 'BARACK OBAMA', year: 1994 },
  { label: 'M. GADAFI', year: 1994 },
  { label: 'B. NETANYAHU', year: 1994 },
  { label: 'HUGO CHÁVEZ', year: 1994 },
  { label: 'GUSTAVO PETRO', year: 1994 },
  { label: 'MICHELLE BACHELET', year: 1994 }
]


export default function ValidAPIStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());


  const [username, setUsername] = React.useState('');
  const [idCredential, setIdCredential] = React.useState('');
  const [passCredential, setPassCredential] = React.useState('');
  const [dataXlsx, setDataXlsx] = React.useState([]);
  const [listObservation, setListObservation] = React.useState([]);
  const [headComment, setHeadComment] = React.useState('');
  const [rucUser, setRucUser] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [validCredential, setvalidCredential] = useState(-2);
  const [fileNameValid,setFileNameValid]=useState('Sube tus comprobantes')
  const [newProcess, setNewProcess] = useState({
    myUuid: "", // Inicializado como string vacío
    username: "", // Inicializado como string vacío
    idCredential: "", // Inicializado como string vacío
    passCredential: "", // Inicializado como string vacío
    rucUser:"",
    dataXlsx: [] // Inicializado como arreglo vacío
  });
  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: 'blue', 
    color: 'white'
  });


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const [shouldRedirect, setShouldRedirect] = useState(false);

  const { myUuid } = useStompClient();
  const { initValidationAPI } = useValidAPIClient();



  const [openTemp, setOpenTemp] = React.useState(false);

  const handleClickTemp = () => {
    setOpenTemp(true);
  };

  const handleCloseTemp = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenTemp(false);
  };


  const navigate = useNavigate();


  const handleClick = async () => {
    setLoading(true); // Activa el indicador de carga
    try {
      const response = await fetch(API_URL+'process-valid-api/is-credential-valid?ruc='+rucUser+'&idCredential='+idCredential+'&passCredential='+passCredential); // Sustituye 'tu_url_aqui' por la URL real
      const data = await response.json(); // Suponiendo que la respuesta es JSON
      // console.log("validnado si es valido la validez del credencial")
      // console.log(data); // Maneja la respuesta
      setvalidCredential(data)
      
    } catch (error) {
      console.error('Hubo un error al realizar la solicitud:', error);
    }
    setLoading(false); // Desactiva el indicador de carga

  };



  useEffect(() => {
    // console.log("pasar por useeffect....")
    // console.log(shouldRedirect)
    if (shouldRedirect) {
      // Aquí puedes también actualizar `dataXlsx` si es necesario antes de redirigir
      // initValidation(newProcess)//websocket no soporte 150 a más

        var infoToRequest={
      id:newProcess.myUuid,nameUser:newProcess.username,state:"",
      process:"",progress:0 ,total:newProcess.dataXlsx.length,
      rucUser:rucUser, passCredential:passCredential,idCredential:idCredential,
      electronicDocumentDTOS:newProcess.dataXlsx

    }


    initValidationAPI(infoToRequest)
      navigate('/validacion', { state: { newProcess } });
      
      
      // Resetear el indicador de redirección si es necesario
      setShouldRedirect(false);
    }
  }, [shouldRedirect, navigate]);
  



  const isStepOptional = (step) => {
    return step === 2; //el index 1 será el opcional
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {

    // console.log("..............dkdkdjd")
  // console.log(activeStep)
if(activeStep===3 && dataXlsx.length===0){
  return
}

  if(activeStep===2 && (rucUser.length>0 || idCredential.length>0 || passCredential.length>0) && validCredential===-2){
    
    handleClickTemp()
    // console.log("debes validar credenaicles....")
    return
  }

    // Aquí podrías incluir la lógica para validar los inputs antes de avanzar al siguiente paso
    let newSkipped = skipped;// newSkipped Set[]
    
    if (isStepSkipped(activeStep)) {// activeStep 0 1 2 3

      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1) {
      // Al llegar al último paso, compilar los datos en newUser
      const newUser = {myUuid, username, rucUser,idCredential, passCredential,dataXlsx };

      setHeadComment('')

      if(validateLimitRows()){

        if(validateHeaders()){

          // console.log("entró cabecera válida")
          var x=validateExcelData()
          // console.log(x)
  
          if(x.length>0){
            generateExcelObservations(x);
            setListObservation(x); 
          }else{
            // console.log("archivo correcto")
            // console.log(myUuid)
            // console.log(newUser)
            setNewProcess(newUser)
            setShouldRedirect(true);
          }
        }else{
          setHeadComment('La cabecera del archivo es incorrecto. ')
          // console.log("cabecera incorrecta")
          // console.log("datos recolectados")
          // console.log(newUser); // Aquí podrías enviar newUser a un backend o manejarlo como necesites
        }
      }else{
          setHeadComment('Solo puedes validar hasta '+MAX_DOCUMENT_VALID+' comprobantes. Modifica tu archivo y envía '+MAX_DOCUMENT_VALID+' comprobantes.')
          // console.log("cabecera incorrecta")
          // console.log("datos recolectados")
          // console.log(newUser); // Aquí podrías enviar newUser a un backend o manejarlo como necesites
        }




    }

    if(activeStep==1 && (username==="" || username===null)){

    }else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {

      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setListObservation([])
    setHeadComment('')
    setActiveStep(0);
  };

  const handleFileUpload = (e) => {
    // console.log("DataXlsx iniciando carga")
    setDataXlsx([])
    const reader = new FileReader();

    const file = e.target.files[0];

    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      setDataXlsx(parsedData)
      // console.log(parsedData)
      // console.log("DataXlsx terminando carga")
      setFileNameValid(file.name)

      setButtonStyle({
        backgroundColor: 'green', 
        color: 'white'
      });

    };
  }

  const generateFormat=()=>{

    const data = [
      { ruc: 10101010101, tipo_cpe: 1, serie: "F001", correlativo: 123,f_emision:"01/01/2024",total:23.1 },
      { ruc: 20101200101, tipo_cpe: 7, serie: "FC01", correlativo: 12345678,f_emision:"01/01/2025",total:723.1 }
    ];

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Comprobantes');

    XLSX.writeFile(wb,"FormatoValidacion.xlsx")
    
  }


  const generateExcelObservations = (excelObs) => {

    // console.log("generando....")
    // console.log(excelObs)
    const ws = XLSX.utils.json_to_sheet(excelObs);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Observaciones');
    XLSX.writeFile(wb, "Observaciones.xlsx");
  };

  const validateLimitRows=()=>{

    if(dataXlsx.length>MAX_DOCUMENT_VALID){
      return false;
    }else{
      return true;
    }
  }

  const validateHeaders=()=>{


    let result=true;
    if(dataXlsx.length==0){
      return false;
    }else{
      dataXlsx.forEach(row=>{

        if(
          row.ruc==undefined ||
          row.tipo_cpe==undefined ||
          row.serie==undefined ||
          row.correlativo==undefined ||
          row.f_emision==undefined ||
          row.total==undefined 
          ){
            result=false;
        } 
    })      
    }

    return result
  }

  const validateExcelData=()=>{

    var listObservations=[];

    dataXlsx.forEach(row=>{

      let message="";

      if(typeof row.ruc === "number"){
        if(row.ruc.toString().length!=11){
          message+="El RUC debe tener 11 dígitos |";  
        }

        if (!row.ruc.toString().startsWith("10") && !row.ruc.toString().startsWith("20") && !row.ruc.toString().startsWith("15")) {
          message += "El RUC debe empezar con 10, 20 o 15 |";
        }

      }
      else{
        message+="El RUC debe ser un número |";       
      }

      if(typeof row.tipo_cpe==="number"){

        if(row.tipo_cpe==1 || row.tipo_cpe==2 || row.tipo_cpe==8 || row.tipo_cpe==7 || row.tipo_cpe==3){
          
        }else{
          message+="El tipo de comprobante debe ser 1 (facturas), 2 (RH), 3 (boletas), 7(n. cred.) u 8 (n. deb.)|";  
        }
      }
      else{
        message+="El tipo de comprobante debe ser un número |";
      }

      if(typeof row.serie==="string"){

        if(row.serie.length!=4){
          message+="La serie debe ser de 4 caracteres |";
        }

      }
      else{
        message+="La serie no debe ser un número |";
      }

      if(typeof row.correlativo==="number"){

      }
      else{
        message+="El correlativo debe ser un número |";
      }


      if(typeof row.f_emision==="string"){

        const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(20\d\d)$/;
        if (!regex.test(row.f_emision)) {
          message += "La fecha de emisión debe estar en el formato DD/MM/AAAA |";
        }

      }
      else{
        message+="La fecha de emisión debe tener formato texto, puedes agregar un ' delante. Ejem. '01/01/2023 |";
      }


      if(typeof row.total==="number"){
        if (Math.round(row.total * 100) / 100 !== row.total) {
          message += "El total no debe tener más de dos decimales |";
        }
        
      }
      else{
        message+="El total debe tener formato nùmero. |";
      }


      if(message.length>0){
        listObservations.push({ ...row, obs: message });
      }
    })

    return listObservations;

  }


    // Modifica el renderizado del contenido de cada paso para incluir los inputs controlados
    const renderStepContent = (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return (
            <p> Mira debajo, podrás observar cuántos usuarios hay en espera. ¿Quieres continuar?</p>

          );
        case 1:
          return (


              <><p>Elije un nombre, el que más te represente</p><Autocomplete
              id="free-solo-demo"
              freeSolo
              value={username} 
              options={randomNames.map((option) => option.label)}
              renderInput={(params) => <TextField {...params} label="Nombre" required inputProps={{
                ...params.inputProps,
                maxLength: 20 // Limita la entrada a20 caracteres
              }}/>}
              onChange={(event, newValue) => setUsername(newValue)}  
              /></>

          );
        case 2:
          return (
            <>
<p>Coloca tus credenciales API <span style={{ color: 'red' }}>(Puedes omitir este paso)</span>.</p>
                  <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Además de saber si un comprobante es válido, puedes confirmar si fue emitido al contribuyente agregando sus credenciales.</Typography>
           
           <p>Si no colocas credenciales o no son válidas, no hay problema, usaremos nuestras propias credenciales.</p>
           <a target='blank' href={URL_CREDENTIAL_API}>¿Cómo genero credenciales?</a>	
          </React.Fragment>
        }
      >
        <Button>¿para qué sirven las credenciales?</Button>
      </HtmlTooltip>
      <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box marginRight={2}>
      <Input
        
        id="input-with-icon-adornment"
        startAdornment={
          <InputAdornment position="start">
            <Key />
          </InputAdornment>
        }
        value={rucUser}
        placeholder="RUC propietario de credenciales" 
        onChange={(e) => {setRucUser(e.target.value)
          setvalidCredential(-2)
        }}
        inputProps={{
          maxLength: 11, // Limita la entrada a 10 caracteres
          
        }}
      />
      </Box>
      <Box marginRight={2}>
        <Input
        
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <Key />
            </InputAdornment>
          }
          value={idCredential}
          placeholder="Id credencial" 
          onChange={(e) => {setIdCredential(e.target.value)
            setvalidCredential(-2)}}
          inputProps={{
            maxLength: 36, // Limita la entrada a 10 caracteres
          }}
        />
        </Box>
        <Box marginRight={2}>
          <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <Key />
            </InputAdornment>
          }
          value={passCredential}
          placeholder="Clave credencial" 
          onChange={(e) => {setPassCredential(e.target.value)
            setvalidCredential(-2)}}
          inputProps={{
            maxLength: 24, // Limita la entrada a 10 caracteres
          }}
          />
        </Box>

        <Box marginRight={2}>


        <Button
      variant="contained"
      endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
      onClick={handleClick}
      disabled={loading} // Desactiva el botón durante la carga
    >
      {loading ? 'Cargando...' : 'Validar'}
    </Button>

      </Box>



        </Box>
        
            </>
          );
        case 3:
          return (

            <><p>Sube tus comprobantes. No olvides que debes usar <a href="#" onClick={generateFormat}>este formato.</a></p>
             
             <Button
              style={buttonStyle}
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      {fileNameValid}
      <VisuallyHiddenInput type="file" accept=".xlsx, .xls" onChange={handleFileUpload}/>
    </Button>

            </>
          );
        default:
          // aquí se colocará un redireccionador, ya que todo es correcto
          return "Unknow step"
            // {console.log(dataXlsx)}
            

      }
    };

  return (

    <>
      <Snackbar
        open={openTemp}
        autoHideDuration={4000}
        onClose={handleCloseTemp}
        message="Debes validar las credenciales"
      />
    


      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        open={validCredential!=-2}

        message={validCredential===1?"¡Credenciales válidas! Asegúrate que pertenezcan al RUC que colocaste":validCredential===0?"Credenciales inválidas. Usaremos nuestras credenciales.":'SUNAT no responde, no pudimos validar las credenciales.'}

      />
    <Box sx={{ width: '100%' }}>
      {/* *********************STEPPER WITH OBJECT STEP********************* */}
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Opcional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* *********************STEPPER WITH OBJECT STEP********************* */}




      {/* CONDICIONAL, si  activeStep (todos los pasos fueron completados) entonces aplicar :a?c */}
      <Box sx={{ minHeight: 170 }}>
        {listObservation.length > 0 || headComment.length > 0 ? (

          // si todos los pasos estàn listos... 
          <React.Fragment>
            <Box sx={{
              display: 'flex', // Habilita flexbox
              flexDirection: 'column', // Alinea los hijos verticalmente
              justifyContent: 'center', // Centra los hijos verticalmente
              minHeight: 150 // Establece la altura mínima del contenedor
            }}>



              {listObservation.length > 0 ? (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  El archivo excel debe corregirse , revisa el archivo "Observaciones.xlsx"
                </Typography>
              ) : (
                <Typography sx={{ mt: 2, mb: 1 }}>
                      {headComment.startsWith("Solo puedes validar")?headComment:{headComment}(<a href="#" onClick={generateFormat}> Descarga este formato.</a>)}

                 
                </Typography>
              )}

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reiniciar</Button>
            </Box>
          </React.Fragment>

        ) : (

          // si todos los pasos no están listos... 
          <React.Fragment>


            <Box sx={{
              display: 'flex',
              flexDirection: 'column', // Alinea los hijos verticalmente
              justifyContent: 'center', // Centra los hijos verticalmente
              minHeight: 150, // Establece la altura mínima del contenedor
              alignItems: 'center',
            }}> {/* Contenedor con altura mínima para el contenido del paso */}

              <Stack spacing={2} sx={{ width: 500 }}>{renderStepContent(activeStep)}</Stack>

            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Atrás
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button style={{ backgroundColor: 'red', color: 'white' }}  onClick={handleSkip} sx={{ mr: 1 }}>
                  OMITIR
                </Button>
              )}

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'FINALIZAR' : 'SIGUIENTE'}
              </Button>
            </Box>
          </React.Fragment>
        )}

      </Box>


    </Box></>
  );
}
