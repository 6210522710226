import * as React from 'react';
import { Box, ThemeProvider, Typography, createTheme } from '@mui/material';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Zoom from '@mui/material/Zoom';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useNavigate } from 'react-router-dom'; // Paso 1: Importar useNavigate

const theme = createTheme({
  palette: {
    primary: {
      main: '#007FFF',
      dark: '#0066CC',
    },
  },
});


const icon = (
  <Paper sx={{ m: 1, width: 200, height: 200 }} elevation={4}>

    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: 200,
          height: 200,
          borderRadius: 1,
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
        }}
      >
    <Typography
      sx={{
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'common.white',
      }}
      variant="h5"
    >
      VALIDADOR COMPROBANTES DE PAGO
    </Typography>
      </Box>

        
    </ThemeProvider>

  </Paper>
);

const icon2 = (
  <Paper sx={{ m: 1, width: 200, height: 200 }} elevation={4}>

    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: 200,
          height: 200,
          borderRadius: 1,
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
        }}
      >
    <Typography
      sx={{
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'common.white',
      }}
      variant="h5"
    >
      PROXIMAMENTE MÁS SERVICIOS...
    </Typography>
      </Box>

        
    </ThemeProvider>

  </Paper>
);

export default function ShowServicesZoom({ className }) {

  const navigate = useNavigate(); // Paso 2: Inicializar useNavigate
  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };


  return (
    <div className={className}>
    <Box sx={{ height: 180 }}>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label="¡Muéstrame las app's!"
      />
      <Box sx={{ display: 'flex' }}>
        <Zoom in={checked}>
        <div onClick={() => navigate('/stepper')} style={{ cursor: 'pointer' }}>
        {icon}
        </div>
        </Zoom>
        <Zoom in={checked} style={{ transitionDelay: checked ? '500ms' : '0ms' }}>

              {icon2}

        </Zoom>
      </Box>
    </Box>
    </div>
  );
}
