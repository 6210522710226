import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Paso 1: Importar useNavigate
import ModalLimitIp from './ModalLimitIp';
import { useStompClient } from './StompClientContext';
import { Typography } from '@mui/material';
import {API_URL} from '../constants/constants'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(id,position,name,state,macro,advance,total) {
  return { id,position,name,state,macro,advance,total};
}


// SOCKET
var stompClient=null;

export default function CustomizedTables() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sample, setSample] = useState([]);
  const navigate = useNavigate(); // Paso 2: Inicializar useNavigate

  const { connectStompClient,stompClient,subscribeListLiveUser, isConnected,myUuid,subscribeInitValidation,isDisconnectError } = useStompClient();

    const onCloseModal=()=>{

      setIsModalOpen(false)
      // console.log("cerrando modal....")
      navigate('/')
    }

    const onMessageReceived = (payload)=>{

      var arrayForTable=[]

      payload.map(function(element, index, array) {

        arrayForTable.push(createData(element.id,element.position,element.nameUser,element.state,element.process,element.progress,element.total))
                            
                // console.log("elemento")
                // console.log(element)
            });

            arrayForTable=[...arrayForTable].sort((a, b) => b.position - a.position);
              setSample(arrayForTable);
    }



    useEffect(()=>{

      if(isDisconnectError){
        setIsModalOpen(true); 
      }

      if(!isConnected){
        connectStompClient()
        
      }
      

      if (isConnected) {
        
   
        subscribeListLiveUser('/topic/live-users', onMessageReceived);
        subscribeInitValidation('/queue/'+myUuid+'/notification');

        var arrayForTable=[]

            
        fetch(API_URL+'process-valid-api/list-all')
        .then(response => { //es necesario el primer then, es una promesa
         
          if (response.status === 429) {//aquí se validará IP
            setIsModalOpen(true); 
          }
  
          return response.json();
        })
        .then(data => {
        
          // console.log("esto recibí")
          //  console.log(data)//infor de procecesos vigentes
          
          data.map(function(element, index, array) {
  
            arrayForTable.push(createData(element.id,element.position,element.nameUser,element.state,element.process,element.progress,element.total))                     
    
            // console.log("elemento")
            // console.log(element)
        });
  
        arrayForTable=[...arrayForTable].sort((a, b) => b.position - a.position);
          setSample(arrayForTable);
  
        })
        .catch(error => {
          console.error('Error fetching data:', error)
      });
      }

            return () => {
                if (stompClient && stompClient.connected) {
                    stompClient.disconnect(() => {
                    // console.log('INTENTANDO limpiando revisar');
                  });
                }
              };
    },[isConnected,isDisconnectError])



  return (
    <>
    
    <br/>
    <Typography
      variant="h5"
      component="h2"
      sx={{
        color: '#424242',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: '20px',
        letterSpacing: '1px',
        textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
        // Ejemplo con gradiente comentado:
        // backgroundImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // WebkitBackgroundClip: 'text',
        // WebkitTextFillColor: 'transparent',
      }}
    >
    Usuarios conectados</Typography>

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Posición</StyledTableCell>
            <StyledTableCell >Nombre</StyledTableCell>
            <StyledTableCell >Estado</StyledTableCell>
            <StyledTableCell >Proceso</StyledTableCell>
            <StyledTableCell >Avance</StyledTableCell>
            <StyledTableCell >Total</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>


        {sample.length > 0 ? (

          sample.map((row) => (
       <StyledTableRow
       key={row.position}
       style={{ backgroundColor: row.id === myUuid ? '#b3e5fc' : '' }} // Cambia '#b3e5fc' por el código de color celeste que prefieras
     >
              <StyledTableCell align="center">{row.position}</StyledTableCell>
              <StyledTableCell >{row.name}{row.id === myUuid ? ' (Tú)' : ''}</StyledTableCell>
              <StyledTableCell >{row.state}</StyledTableCell>
              <StyledTableCell >{row.macro}</StyledTableCell>
              <StyledTableCell align="center">{row.advance}</StyledTableCell>
              <StyledTableCell align="center">{row.total}</StyledTableCell>
            </StyledTableRow>
          ))
        ):(            <TableRow>
          <StyledTableCell colSpan={6} align="center">
            ¡Todo libre! No hay usuarios esperando.
          </StyledTableCell>
        </TableRow>)

        }
        </TableBody>
      </Table>
    </TableContainer>
    <ModalLimitIp open={isModalOpen} handleClose={onCloseModal} />
    </>
    
  );
}

